import React, { useState } from "react"
import Layout from "../components/Layout"
import Navigation from "../components/Navigation"
import "../assets/css/leistungen.css"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"

const Tags = ({ data }) => {
  const [show1, setShow1] = useState(true)
  const [show2, setShow2] = useState(true)
  const [show3, setShow3] = useState(true)
  const [show4, setShow4] = useState(true)

  const [card1, setCard1] = useState(true)
  const [card2, setCard2] = useState(true)
  const [card3, setCard3] = useState(true)
  const [card4, setCard4] = useState(true)
  const {
    eeg,
    eng,
    emg,
    ultraschall,
    psytestung,
    medtherapie,
    schmerztherapie,
    neuraltherapie,
    information,
    close,
    hammer,
    fasern,
    psytestungimg1,
    psytestungimg2,
  } = data
  return (
    <Layout>
      <div className="leistungenTopContainerContainer">
        <div className="leistungenTopContainer">
          <div className="leistungenTopImage">
            <GatsbyImage
              image={getImage(hammer)}
              title={hammer.title}
              alt="Reflexhammer"
            />
          </div>
          <div className="leistungenTopText">
            <p>
              Zur Abklärung neurologischer Erkrankungen sind neben der Anamnese
              (Erhebung der Krankengeschichte) und der eingehenden körperlichen
              Untersuchung vielfach apparative Untersuchungen nötig.
            </p>
          </div>
        </div>
      </div>

      <div className="zeitenHeader">Untersuchungsmethoden</div>
      <div className="leistungenHeaderText">
        <GatsbyImage
          image={getImage(information)}
          title={information.title}
          alt="Information"
        />
        <p>
          Zur Abklärung neurologischer Erkrankungen sind neben der Anamnese
          (Erhebung der Krankengeschichte) und der eingehenden körperlichen
          Untersuchung vielfach apparative Untersuchungen nötig.
        </p>
      </div>

      <div className="row1-container">
        <div className="boxWrapper">
          <div className="box box-down cyan">
            <h2>Elektroenzephalographie</h2>
            <p>
              Diese Untersuchung dient der Darstellung von Hirnströmen. Die
              Aktivität der Hirnrinde führt zu kleinsten ...
            </p>
            <div
              className="leistungenButton"
              onClick={() => setShow1(s => !s)}
              onKeyDown={() => setShow1(s => !s)}
              role="button"
              tabIndex="0"
            >
              <u>
                <i> Mehr Erfahren</i>
              </u>
            </div>
            <div className="cardImage">
              <GatsbyImage image={getImage(eeg)} title={eeg.title} alt="EEG" />
            </div>
          </div>
        </div>

        <div className="boxWrapper">
          <div className="box red">
            <h2>Elektroneurographie</h2>
            <p>
              Nerven leiten Strom, ähnlich wie ein Kabel. Setzt man einen
              Stromimpuls in der Nähe eines Nerven, beispielsweise ...
            </p>
            <div
              className="leistungenButton"
              onClick={() => setShow2(s => !s)}
              onKeyDown={() => setShow2(s => !s)}
              role="button"
              tabIndex="0"
            >
              <u>
                <i> Mehr Erfahren</i>
              </u>
            </div>
            <div className="cardImage">
              <GatsbyImage image={getImage(eng)} title={eng.title} alt="ENG" />
            </div>
          </div>
        </div>

        <div className="boxWrapper">
          <div className="box box-down blue">
            <h2>Elektromyographie</h2>
            <p>
              Diese Untersuchung dient der Testung von einzelnen Muskeln.
              Besonders bei Kraftverlust bzw. Lähmungserscheinungen ...
            </p>
            <div
              className="leistungenButton"
              onClick={() => setShow3(s => !s)}
              onKeyDown={() => setShow3(s => !s)}
              role="button"
              tabIndex="0"
            >
              <u>
                <i> Mehr Erfahren</i>
              </u>
            </div>
            <div className="cardImage">
              <GatsbyImage image={getImage(emg)} title={emg.title} alt="EMG" />
            </div>
          </div>
        </div>
      </div>

      <div className="row2-container">
        <div className="boxWrapper">
          <div className="box orange">
            <h2>Ultraschall - Neurosonographie</h2>
            <p>
              Die Neurosonographie ist die Ultraschalluntersuchung der
              hirnversorgenden Gefäße. ...
            </p>
            <div
              className="leistungenButton"
              onClick={() => setShow4(s => !s)}
              onKeyDown={() => setShow4(s => !s)}
              role="button"
              tabIndex="0"
            >
              <u>
                <i> Mehr Erfahren</i>
              </u>
            </div>
            <div className="cardImage3">
              <GatsbyImage
                image={getImage(ultraschall)}
                title={ultraschall.title}
                alt="Ultraschall"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="leistungenPopUpContainer">
        <div
          className="leistungenPopUp"
          style={{ display: show1 ? "none" : "block" }}
        >
          <div className="leistungenPopUpClose">
            <div
              className="leistungenPopUpCloseIcon"
              onClick={() => setShow1(s => !s)}
              onKeyDown={() => setShow1(s => !s)}
              role="button"
              tabIndex="0"
            >
              <GatsbyImage
                image={getImage(close)}
                title={close.title}
                alt="Fenster schließen"
                imgStyle={{ width: "35px", height: "35px" }}
              />
            </div>
            <p>Fenster schließen</p>
          </div>

          <div className="leistungenPopUpText">
            <h3>Elektroenzephalographie - EEG</h3>
            <p>
              Diese Untersuchung dient der Darstellung von Hirnströmen. Die
              Aktivität der Hirnrinde führt zu kleinsten elektrischen
              Schwankungen bzw. Wellen, die über die Kopfhaut abgeleitet werden
              können.
            </p>
            <p>
              Hierzu sitzt der Patient in angenehmer Ruheposition auf einem
              Liegesessel; an die Kopfhaut werden Elektrodenplättchen gebracht,
              die an der Innenseite einer "Haube" befestigt sind.
            </p>
            <p>
              Routinemäßig erfolgt die Ableitung an 20 festgelegten Stellen der
              Kopfhaut. Zwanzig Minuten werden die Hirnströme aufgezeichnet. Die
              einzelnen Kabel führen zu einem Verstärker - die Signale werden
              letzlich über einen Computer dargestellt, aufgezeichnet und
              ausgewertet.
            </p>
            <p>
              Zur Anwendung kommt das EEG vornehmlich bei der Abklärung
              (möglicher) epileptischer Erkrankungen, also "Krampfleiden", da
              sich hier besonders deutliche Abnormitäten in den Hirnströmen
              finden.
            </p>
            <p>
              Die Untersuchung wird auch allgemein im Rahmen der Abklärung
              plötzlicher unklarer Bewußtlosigkeitszustände eingesetzt.
            </p>
            <p>
              Aber auch andere Störungen des Zentralnervensystems, wie
              Entzündungen, Stoffwechselstörungen, Abbauerkrankungen wie
              Alzheimer-Demenz führen zu Veränderungen im EEG.
            </p>
          </div>
        </div>
      </div>

      <div className="leistungenPopUpContainer">
        <div
          className="leistungenPopUp"
          style={{ display: show2 ? "none" : "block" }}
        >
          <div className="leistungenPopUpClose">
            <div
              className="leistungenPopUpCloseIcon"
              onClick={() => setShow2(s => !s)}
              onKeyDown={() => setShow2(s => !s)}
              role="button"
              tabIndex="0"
            >
              <GatsbyImage
                image={getImage(close)}
                title={close.title}
                alt="Fenster schließen"
                imgStyle={{ width: "35px", height: "35px" }}
              />
            </div>
            <p>Fenster schließen</p>
          </div>

          <div className="leistungenPopUpText">
            <h3>Elektroneurographie - ENG</h3>
            <p>
              Nerven leiten Strom, ähnlich wie ein Kabel. Setzt man einen
              Stromimpuls in der Nähe eines Nerven, beispielsweise an der
              darüber liegenden Haut, so wird eine elektrische Entladung im
              Nerven ausgelöst, die sich fortlaufend mit einer gewissen
              Geschwindigkeit und Stärke ausbreitet. Die
              Nervenleitgeschwindigkeit liegt etwa im Bereich des Ellennerven
              bei 50 Meter pro Sekunde, das sind 180 km/h (!).
            </p>
            <p>
              Bei gewissen Erkrankungen (z.B. Polyneuropathien) ist die
              Nervenleitgeschwindigkeit vermindert. Bei anderen Störungen (z.B.
              Carpaltunnelsyndrom - CTS) ist sie nur im Bereich einer kürzeren
              Strecke (Im Falle des CTS unter dem Querband, das die Beugesehen
              im Handgelenksbereich fixiert) herabgesetzt.
            </p>
            <h4>Wie funktioniert ein ENG?</h4>
            <p>
              Ein zu messender motorischer Nerv (das ist ein Nerv, der für einen
              Muskel zuständig ist, also eine Bewegung steuert), wird an einer
              Stelle mit einem schwachen elektrischen Impuls gereizt. Der Reiz
              breitet sich als Entladung im Nerven aus, bis der Zielmuskel
              erregt wird (sichtbar in Form einer kurzen Zuckung). Die
              elektrische Entladung wird mit einer an der Haut über dem Muskel
              aufgebrachten Plättchen-Elektrode abgeleitet und über einen
              Computer am Bildschirm sichtbar gemacht. Wird der Nerv an
              verschiedenen Stellen gereizt, kann über die Unterschiede der
              gemessenen Wegzeiten die Nervenleitgeschwindigkeit errechnet
              werden.
            </p>
            <p>
              Ein sensibler Nerv, das ist ein Nerv, der Gefühlsempfindungen
              beispielsweise aus der Haut bis zum Rückenmark leitet, wird in
              gleicher Art getestet. Die Elektrode wird über der Haut
              aufgebracht, der Nerv gereizt – die Entladung breitet sich aus und
              wird schließlich von der Elektrode aufgezeichnet.
            </p>
            <p>
              Die Untersuchung ist an sich nicht schmerzhaft, individuell ist
              die Empfindlichkeit gegenüber, wenn auch schwachen, Stromimpulsen
              sehr unterschiedlich. Nicht wenige meiner Patienten sagen, es
              fühle sich an wie ein "Holta-Bua" (oststeirisch für "elektrischer
              Weidezaun").
            </p>
          </div>
        </div>
      </div>

      <div className="leistungenPopUpContainer">
        <div
          className="leistungenPopUp"
          style={{ display: show3 ? "none" : "block" }}
        >
          <div className="leistungenPopUpClose">
            <div
              className="leistungenPopUpCloseIcon"
              onClick={() => setShow3(s => !s)}
              onKeyDown={() => setShow3(s => !s)}
              role="button"
              tabIndex="0"
            >
              <GatsbyImage
                image={getImage(close)}
                title={close.title}
                alt="Fenster schließen"
                imgStyle={{ width: "35px", height: "35px" }}
              />
            </div>
            <p>Fenster schließen</p>
          </div>

          <div className="leistungenPopUpText">
            <h3>Elektromyographie - EMG</h3>
            <p>
              Diese Untersuchung dient der Testung von einzelnen Muskeln.
              Besonders bei Kraftverlust bzw. Lähmungserscheinungen einzelner
              Muskeln kommt diese Methode zur Anwendung. Jedoch auch feinere
              krankhafte Veränderungen in einem Muskel, die noch nicht zu einem
              merklichen Kraftverlust geführt haben, lassen sich mit dieser
              Methode erfassen.
            </p>
            <p>
              Muskeln werden durch Nerven aktiviert, durch das Zusammenziehen
              von einzelnen Muskelfasern entsteht die Anspannung und damit
              Kraftentfaltung des gesamten Muskels.
            </p>

            <p>
              Dieses "Zusammenziehen" (die Kontraktion) von Muskelfasern sendet
              elektrische Impulse auf - diese können aufgezeichnet und
              ausgewertet werden.
            </p>
            <p>
              Hierzu ist es notwendig, eine Elektrode (leitfähiges Material)
              ganz in die Nähe der einzelnen Muskelfasern zu bringen. Hierzu
              wird eine feine Nadel durch die Haut in den Muskel eingeführt. Die
              Untersuchung ist dadurch nicht ganz "schmerzfrei", allerdings
              handelt es sich um sehr dünne Nadelelektroden, und die Anzahl der
              untersuchten Stellen wird so gering als gerade notwendig gehalten.
            </p>
            <div className="leistungenPopUpTextFasernImage">
              <GatsbyImage
                image={getImage(fasern)}
                title={fasern.title}
                alt="Muskelfasern"
              />
            </div>
            <p>
              Die Auswertung der Muskelimpulse zeigt, ob der Muskel selbst
              erkrankt ist, oder ob er durch eine Störung oder Lähmung seines
              versorgenden Nerven in der Funktion beeinträchtigt ist.
            </p>
            <p>
              Auch die Maximale Kraftentfaltung kann mit dieser Methode sehr gut
              gemessen werden.
            </p>
            <p>
              Beispiele für die Anwendung dieser Untersuchung sind z.B.
              Nervenverletzungen nach Unfällen, mit Lähmungserscheinungen - hier
              kann über die betroffenen Muskeln die Diagnose gestellt werden,
              welche Nerven geschädigt sind, und die Schädigungsstelle im Nerven
              kann genauer ermittelt werden.
            </p>
            <p>
              Ein anderes Beispiel sind Bandscheibenschäden, die durch Druck auf
              Nervenwurzeln zu Schwäche in den Extremitäten führen, zum Beispiel
              zu einer Fußheberschwäche.
            </p>
            <p>
              Häufig kommt es bei sogenannten "Engpaßsyndromen", wie dem
              Carpaltunnelsyndrom, durch eine Schädigung des Nerven auch zu
              einer Veränderung im entsprechenden Muskel - hier kann das EMG
              bereits sehr frühzeitig Veränderungen fassbar machen.
            </p>
            <p>
              Beim Carpaltunnelsyndrom kommt es durch anlagebedingte
              Bindegewebsverdickung und oft zusätzliche mechanische Belastung zu
              einer Enge am Handgelenk und damit einer Irritation des mittleren
              Handnerven, die soweit gehen kann, daß der Nerv mit der Zeit
              Fasern verliert, die entsprechenden Handmuskeln schlechter
              versorgt und letztlich diese Muskeln sichtbar schrumpfen können,
              neben den typischen nächtlichen Schmerzen und Gefühlsstörungen,
              die charakteristisch für dieses Engpaß-Syndrom sind.
            </p>
            <p>
              Ein weiteres Beispiel für Veränderte elektrische Muskelaktivität
              wäre eine rheumatische Muskelentzündung - hier ist der Muskel
              selbt erkrankt und zeigt im EMG ein typisches elektrisches Muster.
              Weitere seltenere Eigenerkrankungen der Muskeln können ebenfalls
              mit dieser Methode erfaßt werden.
            </p>
          </div>
        </div>
      </div>

      <div className="leistungenPopUpContainer">
        <div
          className="leistungenPopUp"
          style={{ display: show4 ? "none" : "block" }}
        >
          <div className="leistungenPopUpClose">
            <div
              className="leistungenPopUpCloseIcon"
              onClick={() => setShow4(s => !s)}
              onKeyDown={() => setShow4(s => !s)}
              role="button"
              tabIndex="0"
            >
              <GatsbyImage
                image={getImage(close)}
                title={close.title}
                alt="Fenster schließen"
                imgStyle={{ width: "35px", height: "35px" }}
              />
            </div>
            <p>Fenster schließen</p>
          </div>
          <div className="leistungenPopUpText">
            <h3>Ultraschall - Neurosonographie</h3>
            <p>
              Die Neurosonographie ist die Ultraschalluntersuchung der
              hirnversorgenden Gefäße.
            </p>
            <p>
              Verkalkungen und Verengungen im Bereich der Halsschlagadern sind
              im höheren Alter oder bei Vorliegen mehrerer Risikofaktoren (wie
              z.B. Rauchen und hoher Blutdruck) häufig.
            </p>

            <p>
              Mittels Gefäß-Ultraschall können die Beschaffenheit der
              Gefäßwändende, das Kaliber des Gefäßes, eventuelle Verengungen und
              Auffälligkeiten im Flussprofil beurteilt werden.
            </p>
            <p>
              Gefäßverengungen der Halsschlagadern kommen insbesondere bei
              Personen mit erhöhtem Arteriosklerose-Risiko vor. Hier kommt die
              Ultraschalluntersuchung der hirnversorgenden Blutgefäße einerseits
              in der Vorsorge zum Einsatz, andererseits selbstverständlich in
              der Nachsorge, also Verlaufskontrolle und Beobachtung bereits
              diagnostizierter Verengungen bzw. Anomalien in diesen Blutgefäßen.
            </p>
          </div>
        </div>
      </div>

      <div className="leistungenCardContainerWrapper">
        <div className="leistungenCardContainerMain">
          <div className="leistungenCardContainer">
            <div className="zeitenHeader2">Psychologische Testung</div>
            <div className="leistungenCardModuleContainer">
              <div className="leistungenCardModule">
                <div className="leistungenCardModuleImage">
                  <GatsbyImage
                    image={getImage(psytestung)}
                    title={psytestung.title}
                    alt="Psychologische Testung"
                  />
                </div>
                <p>
                  Eine klinisch-psychologische Untersuchung mittels
                  standardisierter Testverfahren ermöglicht eine genaue
                  Erfassung Ihrer geistigen, körperlichen und sozialen
                  Fähigkeiten. ...
                </p>
                <div className="leistungenCardModuleButton">
                  <u
                    onClick={() => setCard1(s => !s)}
                    onKeyDown={() => setCard1(s => !s)}
                    role="button"
                    tabIndex="0"
                  >
                    <i style={{ display: card1 ? "block" : "none" }}>
                      Mehr Infos
                    </i>
                    <i style={{ display: card1 ? "none" : "block" }}>
                      Weniger Infos
                    </i>
                  </u>
                </div>
              </div>
            </div>
            <div className="leistungenCardModuleContainer">
              <div
                className="leistungenCardModuleExtended"
                style={{ display: card1 ? "none" : "block" }}
              >
                <div className="leistungenCardModuleExtendedText">
                  <GatsbyImage
                    image={getImage(psytestungimg1)}
                    title={psytestungimg1.title}
                    alt="Fit im Kopf"
                  />
                  <p>
                    Die Testung umfasst je nach Bedarf Anamnesegespräch,
                    detaillierte Diagnostik der Gedächtnisleistung, Demenz- und
                    Depressionsabklärung sowie Beurteilung der vorhandenen
                    Ressourcen.
                  </p>
                  <div
                    style={{
                      maxWidth: "300px",
                    }}
                  >
                    <GatsbyImage
                      image={getImage(psytestungimg2)}
                      title={psytestungimg2.title}
                      alt="Fit im Kopf"
                    />
                  </div>
                  <p>
                    Die Tests führt Frau Dr. Manuela Meier-Ružić, klinische- und
                    Gesundheitspsychologin, im Rahmen der Durchuntersuchung
                    durch. Die Testung dauert gut eine Stunde, wobei sie in
                    angenehmer stressfreier Athmosphäre durchgeführt wird.
                  </p>
                  <p>
                    Die Ergebnisse werden anhand von Vergleichspatientendaten
                    mit ähnlichem Alter und Bildungsstandard .(z.B. Datenbank
                    Univ.-Klinik Basel, Schweiz) ausgewertet.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="leistungenCardContainer">
            <div className="zeitenHeader2">Medikamentöse Therapie</div>
            <div className="leistungenCardModuleContainer">
              <div className="leistungenCardModule">
                <div className="leistungenCardModuleImage">
                  <GatsbyImage
                    image={getImage(medtherapie)}
                    title={medtherapie.title}
                    alt="Medikamentöse Therapie"
                  />
                </div>
                <p>
                  Für viele neurologische Krankheitsbilder wurden in den letzen
                  Jahrzenten und insbesondere in den letzen Jahren sehr gut
                  wirksame medikamentöse Therapieverfahren entwickelt. ...
                </p>
                <div className="leistungenCardModuleButton">
                  <u
                    onClick={() => setCard2(s => !s)}
                    onKeyDown={() => setCard2(s => !s)}
                    role="button"
                    tabIndex="0"
                  >
                    <i style={{ display: card2 ? "block" : "none" }}>
                      Mehr Infos
                    </i>
                    <i style={{ display: card2 ? "none" : "block" }}>
                      Weniger Infos
                    </i>
                  </u>
                </div>
              </div>
            </div>
            <div className="leistungenCardModuleContainer">
              <div
                className="leistungenCardModuleExtended"
                style={{ display: card2 ? "none" : "block" }}
              >
                <div className="leistungenCardModuleExtendedText">
                  <p>
                    Ein Beispiel - die Parkinson-Erkrankung: Durch optimale
                    Therapieeinstellung gelingt es vielfach,
                    Parkinson-Erkrankten über viele Jahre einen weitgehend
                    normalen Alltag und ein normales Leben zu ermöglichen.
                    Öfters ist hiefür auch eine Kombination von mehreren
                    Präpraraten erforderlich. Durch eine kontinuierliche
                    fachärztliche Überwachung kann gegebenenfalls auf Umstände
                    wie vermehrte Krankheitsaktivität rasch reagiert werden.
                  </p>
                  <p>
                    Mein persönliches Motto betreffend Medikamenten-Therapie
                    lautet: Gezielt und mit Augenmaß behandeln, gute Aufkärung,
                    Berücksichtigung von Wechselwirkungen, unkomplizierte
                    Erreichbarkeit (Telefonkontakt) bei Verdacht auf
                    Nebenwirkungen, gute Kommunikation mit dem behandelnden
                    Hausarzt.
                  </p>
                  <p>
                    Weitere Beispiele von neurologischen Erkrankungen, bei denen
                    Medikamente eine wesentliche Rolle im Behandlungskonzept
                    einnehmen:
                  </p>
                  <ul>
                    <li>Multiple Sklerose</li>
                    <li>Alzheimer-Demenz</li>
                    <li>Behandlung des akuten Migraene-Anfalls</li>
                    <li>erbliches Zittern</li>
                    <li>und viele mehr...</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="leistungenCardContainerMain">
          <div className="leistungenCardContainer">
            <div className="zeitenHeader2">Schmerztherapie</div>
            <div className="leistungenCardModuleContainer">
              <div className="leistungenCardModule">
                <div className="leistungenCardModuleImage">
                  <GatsbyImage
                    image={getImage(schmerztherapie)}
                    title={schmerztherapie.title}
                    alt="Schmerztherapie"
                  />
                </div>
                <p>
                  Schmerzen sind ein häufiges Symptom unter Patienten, die einen
                  Neurologen aufsuchen. Vielfach haben Schmerzen zwar keine
                  "neurologische" Ursache, und gilt es natürlich vorerst, genau
                  abzuklären, wodurch ein Schmerzbild hervorgerufen wird. ...
                </p>
                <div className="leistungenCardModuleButton">
                  <u
                    onClick={() => setCard3(s => !s)}
                    onKeyDown={() => setCard3(s => !s)}
                    role="button"
                    tabIndex="0"
                  >
                    <i style={{ display: card3 ? "block" : "none" }}>
                      Mehr Infos
                    </i>
                    <i style={{ display: card3 ? "none" : "block" }}>
                      Weniger Infos
                    </i>
                  </u>
                </div>
              </div>
            </div>
            <div className="leistungenCardModuleContainer">
              <div
                className="leistungenCardModuleExtended"
                style={{ display: card3 ? "none" : "block" }}
              >
                <div className="leistungenCardModuleExtendedText">
                  <p>
                    Ausreichende Schmerzbehandlung ist natürlich in jedem
                    Stadium eines Schmerzprozesses absolut notwendig, auch wenn
                    manchmal im Rahmen der laufenden Untersuchungen eine ganz
                    eindeutige Ursachen-Zuordnung noch nicht möglich ist.
                  </p>
                  <p>
                    Häufige Schmerz-Syndrome, welche Betroffen zum Neurologen
                    führen, sind insbesondere Kopf- oder Gesichtsschmerzen,
                    Nackenschmerzen, Kreuzschmerzen, und Nervenschmerzen, wie
                    sie beispielsweise durch sogenannte Nerven
                    "Engpass-Syndrome" wie das Carpaltunnelsyndrom,
                    hervorgerufen werden, oder auch durch
                    Nervenleitungsstörungen wie bei der sogenannten
                    "Polyneuropathie" (Mehr-Nervenerkrankung oder im Volksmund:
                    "Sockenkrankheit").
                  </p>
                  <p>
                    Das Konzept lautet also: Möglichst rasche Abklärunge der
                    Schmerz-Ursache, gleichzeitige Schmerzbehandlung und
                    Einleitung zusätzlicher Maßnahmen, wie zum Beispiel
                    Physiotherapie.
                  </p>
                  <p>
                    Die Schmerztherapie erfolgt einerseits von medikamentöser
                    Seite, des weiteren steht in meiner Ordination das Konzept
                    der "Neuraltherapie" (Injektionsbehandlung an speziellen
                    "Triggerpunkten") zur Verfügung, für deren Anwendung ich das
                    Spezialdiplom der Österr. Ärztekammer besitze, oder bei
                    Bedarf auch (als Möglichkeit der lokalen physikalischen
                    Schmerzbehandlung) die Niedrig-Dosis-Lasertherapie
                    ("Flächenlaser-Therapie"), mit der speziell bei schon ins
                    chronische gehende Schmerzen gute Erfolge zu erzielen sind.
                  </p>
                  <p>
                    Ich arbeite natürlich mit anderen Kollegen und Institutionen
                    zusammen, denn Schmerztherapie bedeutet in vielen Fällen
                    auch einen Therapieansatz von mehreren Seiten.
                  </p>
                  <p>
                    Hier möchte ich zum Beispiel die Zusammenarbeit mit der
                    Schmerzambulanz des Marienkrankenhauses Vorau nennen, die
                    speziell bei Wirbelsäulenschmerzen, welche oft auch zusammen
                    mit Nervenwurzelschmerzen, zum Beispiel durch
                    Bandscheibenschäden, auftreten, effiziente
                    Behandlungsmethoden (wie zum Beispiel die CT-gezielte
                    Nervenwurzelblockade), anbietet.
                  </p>
                  <p>
                    Der chronische Schmerz ist ein zunehmend häufiges Problem,
                    wobei speziell auch hier eine Zusammenarbeit von
                    Spezialisten aus mehreren Fachrichtungen besonders wichtig
                    ist. Hier ist neben dem Neurologen der Orthopäde, der
                    Anästhesist, und auch der Psychiater bzw. Psychologe zu
                    nennen, da der chronische Schmerz im Regelfall auch in einer
                    über das rein körperliche gehenden Dimension gesehen und
                    behandelt werden muß.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="leistungenCardContainer">
            <div className="zeitenHeader2">Neuraltherapie</div>
            <div className="leistungenCardModuleContainer">
              <div className="leistungenCardModule">
                <div className="leistungenCardModuleImage">
                  <GatsbyImage
                    image={getImage(neuraltherapie)}
                    title={neuraltherapie.title}
                    alt="Neuraltherapie"
                  />
                </div>
                <p>
                  Unter dieser Therapieform versteht man die Beeinflussung von
                  nervalen Strukturen in Haut, Bindegewebe oder Muskel, durch
                  Einbringung von lokal (örtlich) wirkenden Substanzen mittels
                  Injetion (zumeist mit sehr feiner Nadel) ...
                </p>
                <div className="leistungenCardModuleButton">
                  <u
                    onClick={() => setCard4(s => !s)}
                    onKeyDown={() => setCard4(s => !s)}
                    role="button"
                    tabIndex="0"
                  >
                    <i style={{ display: card4 ? "block" : "none" }}>
                      Mehr Infos
                    </i>
                    <i style={{ display: card4 ? "none" : "block" }}>
                      Weniger Infos
                    </i>
                  </u>
                </div>
              </div>
            </div>
            <div className="leistungenCardModuleContainer">
              <div
                className="leistungenCardModuleExtended"
                style={{ display: card4 ? "none" : "block" }}
              >
                <div className="leistungenCardModuleExtendedText">
                  <p>
                    Im Körper befinden sich zahlreiche sogenannte
                    "Triggerpunkte", die sich durch eine besondere Dichte an
                    feinsten Nervenfasern (beispilesweise in speziellen
                    Muskelregionen) auszeichnen.
                  </p>
                  <p>
                    Nervenfasern und Nervenganglien werden durch das lokal
                    wirkende Mittel in ihrer Leitfähigkeit und Aktivität
                    beeinflusst.
                  </p>
                  <h4>Anwendung der Neuraltherapie</h4>
                  <p>
                    Da die gesamte Regulation unseres Körpers im Sinne eines
                    weitverzweigten Netzwerkes organisiert ist, kommt es
                    hierdurch zur (positiven) Beeinflussung von
                    Regulationsvorgängen, sodass Heilungsprozesse in Gang
                    gesetzt werden können und Schmerzprozesse unterdrückt und
                    hinunterreguliert werden können. Zumeist ist eine mehrfache
                    Anwendung erforderlich (einige "Sitzungen"), manchmal sind
                    bereits nach ein oder zwei Anwendungen gute Erfolge zu
                    erzielen.
                  </p>
                  <p>
                    Klassische Anwendungsgebiete in meiner Fachrichtung sind der
                    Kopfschmerz, sowie auch der Nacken- und Rückenschmerz.
                  </p>
                  <h4>Regulationstherapie</h4>
                  <p>
                    Da die Neuraltherapie Regulationsvorgänge im Körper
                    beeinflusst, wird sie auch als "Regulationstherapie"
                    bezeichnet. Hier gibt es auch gewisse Querbeziehungen zur
                    Akupunktur, welche ja ebenfalls darauf abzielt, aus der Bahn
                    geratene Regulatonsvorgänge des Organismus wieder "in´s Lot"
                    zu bringen.
                  </p>
                  <p>
                    Mehr Informationsnen über Neural- und Regulatiuonstherapie
                    sind auf der Website der{" "}
                    <a href="https://neuraltherapie.at/">
                      österreichischen Gesellschaft für Neuraltherapie und
                      Regulationsforschung
                    </a>{" "}
                    zu finden.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Navigation></Navigation>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query OffersQuery {
    eeg: contentfulAsset(contentful_id: { eq: "362wFo08KVF3v2yWIo3Njt" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    eng: contentfulAsset(contentful_id: { eq: "5807LeVpYYGsPjukHFi4eN" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    emg: contentfulAsset(contentful_id: { eq: "59GewcPuNGHQOmIq58g5Jm" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    ultraschall: contentfulAsset(
      contentful_id: { eq: "60zKGaNs3axsFrK3KPP0L7" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    psytestung: contentfulAsset(
      contentful_id: { eq: "3jgkLROt6CBYCxjfFQ1S4n" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    medtherapie: contentfulAsset(
      contentful_id: { eq: "1mhdTOH8gKsgyK2YCXWGo5" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    schmerztherapie: contentfulAsset(
      contentful_id: { eq: "4iPE44gPirj3eNFKo0E2Ce" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    neuraltherapie: contentfulAsset(
      contentful_id: { eq: "WPJvGDs3NsDgCoc4eE5C2" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    information: contentfulAsset(
      contentful_id: { eq: "1aodisFRq5NhCZBAoCihDA" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    close: contentfulAsset(contentful_id: { eq: "xWcjaaSqOPQ9EmLoFGapt" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    hammer: contentfulAsset(contentful_id: { eq: "5Llq0zt0FGbW58WQz5mjEh" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    fasern: contentfulAsset(contentful_id: { eq: "6k3rkl9zYL1SHff4I9Sfkv" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    psytestungimg1: contentfulAsset(
      contentful_id: { eq: "38h7Yq6474XBNTCI3B6QVp" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    psytestungimg2: contentfulAsset(
      contentful_id: { eq: "3JFkGslNefAfkf7JmbCGwr" }
    ) {
      gatsbyImageData(quality: 100)
      title
      description
    }
  }
`
